<template>
  <div>
    <div class="ma-4" v-for="sysTable in sysTables" :key="sysTable.tableName">
      <SysTableCRUD :headers="sysTable.headers" :tableName="sysTable.tableName" :title="sysTable.title"
        :parentTables="sysTable.parentTables" :primaryKey="sysTable.primaryKey" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SysTableCRUD from "@/components/SysTableCRUD.vue";

export default Vue.extend({
  components: {
    SysTableCRUD,
  },
  data() {
    return {
      sysTables: [
        {
          tableName: "sys-component",
          title: "Components",
          primaryKey: "code",
          headers: [
            { name: "description_eng", type: "string" },
            { name: "description_lao", type: "string" },
            { name: "code", type: "string" },
            { name: "code_order", type: "number" },
          ],
        },
        {
          tableName: "sys-element",
          title: "Elements",
          primaryKey: "code",
          headers: [
            { name: "description_eng", type: "string" },
            { name: "description_lao", type: "string" },
            { name: "code", type: "string" },
            { name: "code_order", type: "number" },
            { name: "component", type: "parent" },
          ],
          parentTables: [
            {
              name: "sys-component",
              column: "component",
              primaryKey: "code",
              label: "description_eng",
            },
          ],
        },
        {
          tableName: "sys_element_material",
          primaryKey: "code",
          title: "Element materials",
          headers: [
            { name: "description_eng", type: "string" },
            { name: "description_lao", type: "string" },
            { name: "code", type: "string" },
            { name: "code_order", type: "number" },
          ],
        },
        {
          tableName: "sys_component_factor",
          primaryKey: "id",
          title: "Component factor",
          headers: [
            { name: "component_factor", type: "number" },
            { name: "component", type: "parent" },
          ],
          parentTables: [
            {
              name: "sys-component",
              column: "component",
              primaryKey: "code",
              label: "description_eng",
            },
          ],
        },
        {
          tableName: "sys_element_factor",
          primaryKey: "id",
          title: "Element factor",
          headers: [
            { name: "element_factor", type: "number" },
            { name: "component", type: "parent" },
            { name: "element", type: "parent" },
          ],
          parentTables: [
            {
              name: "sys-component",
              column: "component",
              primaryKey: "code",
              label: "description_eng",
            },
            {
              name: "sys-element",
              column: "element",
              primaryKey: "code",
              label: "description_eng",
            },
          ],
        },
        {
          tableName: "sys_lcc_threshold",
          primaryKey: "id",
          title: "LCC Threshold",
          headers: [
            { name: "description_eng", type: "string" },
            { name: "description_lao", type: "string" },
            { name: "hi", type: "number" },
            { name: "repair_level", type: "string" },
          ],
        },
        {
          tableName: "sys_life_span",
          primaryKey: "id",
          title: "Life span",
          headers: [
            { name: "life_span", type: "number" },
            { name: "bridge_material", type: "parent" },
          ],
          parentTables: [
            {
              name: "sys-element-material",
              column: "bridge_material",
              primaryKey: "code",
              label: "description_eng",
            },
          ],
        },
        {
          tableName: "sys_model_parameter",
          primaryKey: "id",
          title: "Model parameters",
          headers: [
            { name: "damage_factor_a", type: "number" },
            { name: "damage_factor_b", type: "number" },
            { name: "damage_factor_c", type: "number" },
            { name: "damage_factor_d", type: "number" },
            { name: "damage_factor_e", type: "number" },
          ],
        },
        {
          tableName: "inventory_form",
          title: "Inventory forms",
          primaryKey: "name",
          headers: [
            { name: "name", type: "string" },
          ],
        },
        {
          tableName: "inspection_form",
          title: "Inspection forms",
          primaryKey: "name",
          headers: [
            { name: "name", type: "string" },
          ],
        },
        {
          tableName: "inventory_data_status",
          title: "Inventory data status",
          primaryKey: "code",
          headers: [
            { name: "code", type: "string" },
            { name: "description_eng", type: "string" },
            { name: "description_lao", type: "string" },
          ],
        },
        {
          tableName: "inspection_data_status",
          title: "Inspection data status",
          primaryKey: "code",
          headers: [
            { name: "code", type: "string" },
            { name: "description_eng", type: "string" },
            { name: "description_lao", type: "string" },
          ],
        },
      ],
    };
  },
});
</script>

