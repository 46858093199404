<template>
  <v-layout child-flex>
    <v-card class="pa-2 d-flex flex-column justify-space-between">
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('Search')" single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" :headers="internalHeaders" :items="tableItems" :search="search" class="elevation-1" :disable-pagination="!paginate"
        :hide-default-footer="!paginate">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ tableTitle }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="green" dark class="mb-2" v-bind="attrs" v-on="on"><v-icon small
                    class="pr-2">mdi-plus</v-icon>
                  New {{ tableTitle }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <slot name="edition" v-bind:editedItem="editedItem">
                      <v-flex v-for="header in internalHeaders" :key="header.value">
                        <v-text-field v-if="header.type === 'string' &&
                          showPropertyForEdition(header)
                          " v-model="editedItem[header.value]" :label="header.text"></v-text-field>
                        <v-text-field v-if="header.type === 'number' &&
                          showPropertyForEdition(header)
                          " v-model="editedItem[header.value]" type="number" :label="header.text"></v-text-field>
                        <v-switch v-if="header.type === 'boolean' &&
                          showPropertyForEdition(header)
                          " v-model="editedItem[header.value]" :label="header.text"></v-switch>
                      </v-flex>
                      <v-select v-for="p in parentTables" :key="p.name" :items="parentLists[p.name]" :item-text="p.label"
                        :item-value="p.primaryKey" v-model="editedItem[p.column]" :label="p.column"></v-select>
                      <v-select v-for="p in parentSysCodes" :key="p.name" :items="sysCodeLists[p.name]" :item-text="p[description]"
                        :item-value="p.code" v-model="editedItem[p.column]" :label="p.column"></v-select>
                    </slot>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItem">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon small @click="askDeleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
import StaticDataService from "@/services/StaticDataService.js";

export default {
  data() {
    return {
      search: "",
      dialog: false,
      editedIndex: -1,
      editedItem: {},
      loading: false,
      message: null,
      dialogDelete: false,
      tableItems: [],
      parentLists: {},
      sysCodeLists: {},
      disableCode: false,
    };
  },
  props: {
    headers: Array,
    primaryKey: String,
    editedItemHeaders: Array,
    title: String,
    parentTables: {
      type: Array,
      default() {
        return [];
      },
    },
    parentSysCodes: {
      type: Array,
      default() {
        return [];
      },
    },
    addable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    paginate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    editable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    deletable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    tableName: String,
  },
  computed: {
    internalHeaders() {
      const headers = this.headers.map((header) => ({
        sortable: true,
        align: "center",
        value: header.name,
        text: header.name,
        type: header.type,
      }));
      headers.push({ value: "actions" });
      return headers;
    },
    formTitle() {
      return this.editedIndex === -1 ? this.addTitle : this.editTitle;
    },
    addTitle() {
      return `${this.$t("Add")} ${this.tableTitle}`;
    },
    editTitle() {
      return `${this.$t("Edit")} ${this.tableTitle}`;
    },
    tableTitle() {
      return this.$t(this.title);
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  async mounted() {
    this.tableItems = (await StaticDataService.index(this.tableName)).data;

    this.parentTables.forEach(async (p) => {
      this.parentLists[p.name] = (await StaticDataService.index(p.name)).data;
    });
    this.parentSysCodes.forEach(async (p) => {
      this.sysCodeLists[p.name] = this.$store.state.sysCode.sysCodes.filter((x) => x.tbl_name == p.tbl_name && x.fld_name === p.fld_name);
    });
  },
  methods: {
    description() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    editItem(item) {
      this.editedIndex = this.tableItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.disableCode = true;
      this.dialog = true;
    },
    askDeleteItem(item) {
      this.editedIndex = this.tableItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItem() {
      try {
        await StaticDataService.delete(
          this.tableName,
          this.editedItem[this.primaryKey]
        );
        this.tableItems.splice(this.editedIndex, 1);
        this.close();
      } catch (err) {
        console.log(err);
      }
    },

    close() {
      this.disableCode = false;
      this.dialog = false;
      this.dialogDelete = false;
      this.loading = false;
      this.message = "";
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    showPropertyForEdition(header) {
      if (header.value === "actions") {
        return false;
      }
      if (header.value === "parent") {
        return false;
      }
      if (header.value === this.primaryKey && this.disableCode) {
        return false;
      }
      return true;
    },
    async save() {
      try {
        this.loading = true;
        if (this.editedIndex > -1) {
          await StaticDataService.update(
            this.tableName,
            this.editedItem[this.primaryKey],
            this.editedItem
          );
          Object.assign(this.tableItems[this.editedIndex], this.editedItem);
        } else {
          let addedItem = (
            await StaticDataService.add(this.tableName, this.editedItem)
          ).data;
          this.tableItems.push(addedItem);
        }
        this.close();
      } catch (err) {
        this.loading = false;
        this.message =
          err.response.data.details != null
            ? err.response.data.details.errors.map((e) => e.message)
            : "Error saving element.";
      }
    },
  },
};
</script>
