<template>
  <div>
    <div class="ma-4" :key="tableName">
      <SysTableCRUD
        :headers="headers"
        :tableName="tableName"
        :title="title"
        :parentTables="parentTables"
        :primaryKey="primaryKey"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SysTableCRUD from "@/components/SysTableCRUD.vue";

export default Vue.extend({
  components: {
    SysTableCRUD,
  },
  data() {
    return {
        tableName: "sys_inspection_damage",
        title: "Inspection damage",
        primaryKey: "id",
        headers: [
        { name: "inspection_type", type: "string" },
        { name: "component", type: "parent" },
        { name: "element", type: "parent" },
        { name: "element_material", type: "parent" },
        { name: "damage_type", type: "string" },
        { name: "a_rating", type: "boolean" },
        { name: "b_rating", type: "boolean" },
        { name: "c_rating", type: "boolean" },
        { name: "d_rating", type: "boolean" },
        { name: "e_rating", type: "boolean" },
        ],
        parentTables: [
        { name: "sys-component", column: "component", primaryKey: "code", label: "description_eng" },
        { name: "sys-element", column: "element", primaryKey: "code", label: "description_eng" },
        { name: "sys-element-material", column: "element_material", primaryKey: "code", label: "description_eng" },
        ],
    };
  },
});
</script>

