<template>
  <div>
    <div class="ma-4" :key="tableName">
      <SysTableCRUD
        :headers="headers"
        :tableName="tableName"
        :title="title"
        :parentTables="parentTables"
        :primaryKey="primaryKey"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SysTableCRUD from "@/components/SysTableCRUD.vue";

export default Vue.extend({
  components: {
    SysTableCRUD,
  },
  data() {
    return         {
          tableName: "sys_damage_factor",
          title: "Damage factors",
          primaryKey: "id",
          headers: [
            { name: "component", type: "parent" },
            { name: "element", type: "parent" },
            { name: "damage_type", type: "parent" },
            { name: "damage_factor", type: "number" },
          ],
          parentTables: [
            { name: "sys-component", column: "component", primaryKey: "code", label: "description_eng" },
            { name: "sys-element", column: "element", primaryKey: "code", label: "description_eng" },
            { name: "element-damage", column: "damage_type", primaryKey: "damage_type", label: "damage_type" },
          ],
        };
  },
});
</script>

