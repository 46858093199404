<template>
  <div>
    <div class="ma-4" :key="tableName">
      <SysTableCRUD :headers="headers" :tableName="tableName" :title="title" :primaryKey="primaryKey" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SysTableCRUD from "@/components/SysTableCRUD.vue";

export default Vue.extend({
  components: {
    SysTableCRUD,
  },
  data() {
    return {
      tableName: "sys_code",
      title: "Various codes",
      primaryKey: "id",
      headers: [
        { name: "tbl_name", type: "string" },
        { name: "fld_name", type: "string" },
        { name: "code", type: "string" },
        { name: "description_eng", type: "string" },
        { name: "description_lao", type: "string" },
        { name: "code_order", type: "number" },
      ],
    };
  },
});
</script>



