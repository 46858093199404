<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-text>
        <br />
        <div v-if="success">
          <p style="color: green">{{ successMessage[$i18n.locale] }}</p>
        </div>
        <div v-else>
          <p style="color: red">{{ errorMessage[$i18n.locale] }}</p>
          <p style="color: black">{{ errorData }}</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="toggleModal">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "success-error-modal",
  props: {
    dialog: { required: true, type: Boolean },
    success: { default: true },
    successMessage: {
      default() {
        return {
          en: "Modifications successfully saved.",
          la: "Modifications successfully saved.",
        };
      },
      type: Object,
    },
    errorMessage: {
      default() {
        return {
          en: "Error",
          la: "Error",
        };
      },
      type: Object,
    },
    errorData: { default: "", type: String },
  },
  methods: {
    toggleModal() {
      this.$emit("toggleModal");
    },
  },
};
</script>
