<template>
  <div>

    <div class="ma-4" :key="tableName" v-if="!loading">
      <SysTableCRUD :headers="headers" :tableName="tableName" :title="title" :primaryKey="primaryKey" :paginate="true" />
      <UploadButton title="LINKS" @file-update="uploadFile" :data="{ type: 'links' }" small color="secondary"
        class="ma-2">
        <template slot="icon-left">
          <v-icon left dark>mdi-arrow-up-bold-box-outline</v-icon>
        </template>
      </UploadButton>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SysTableCRUD from "@/components/SysTableCRUD.vue";
import UploadButton from "@/components/main/UploadButton.vue";
import BridgeService from "@/services/BridgeService";
import * as XLSX from 'xlsx';

export default Vue.extend({
  components: {
    SysTableCRUD,
    UploadButton
  },
  data() {
    return {
      tableName: "link",
      title: "Link",
      primaryKey: "id",
      loading: false,
      headers: [
        { name: "road_number", type: "string" },
        { name: "link_id", type: "string" },
        { name: "road_number", type: "string" },
        { name: "mti", type: "number" },
        { name: "socio_eco_factor", type: "number" },
        { name: "mti_factor", type: "number" },
      ],
    };
  },
  methods: {
    async uploadFile(file) {
      this.message = "Uploading file";
      this.loading = true;
      this.result_dialog = true;
      let type = file.data.type;
      this.parseExcel(file, type);
    },
    parseExcel(file, type) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: "binary",
          dense: true,
        });

        const worksheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];

        worksheet['!ref'] = 'A1:JJ10000'

        let rows = XLSX.utils.sheet_to_row_object_array(
          worksheet,
          { defval: "" }
        );
        await BridgeService.ingestLinks(rows);
        this.loading = false;
      };

      reader.onerror = function (ex) {
        console.log(ex);
      };

      reader.readAsBinaryString(file);
    },
    renameForTable(columns, row) {
      const dbColumns = Object.keys(columns);
      const data = {};
      dbColumns.forEach((c) => {
        data[c] = row[columns[c]];
      });
      return data;
    },
  }
});
</script>

