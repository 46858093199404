import Api from "@/services/Api";

export default {
  computeBaseline() {
    return Api().get("compute/baseline");
  },
  computeElementScenarios() {
    return Api().get("compute/element-scenarios");
  },
  computeConstrainedWork(analysis_id) {
    return Api().get(`compute/constrained-work/${analysis_id}`);
  },

  computeScenarioMinimum(analysis_id) {
    return Api().get(`compute/scenario-minimum/${analysis_id}`);
  },

};

