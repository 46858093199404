var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',{staticClass:"pa-2 d-flex flex-column justify-space-between"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{ 'items-per-page-text':''},"no-data-text":_vm.$t('No data available'),"headers":_vm.internalHeaders,"items":_vm.tableItems,"search":_vm.search,"disable-pagination":!_vm.paginate,"hide-default-footer":!_vm.paginate},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.tableTitle))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"green","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" New "+_vm._s(_vm.tableTitle)+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_vm._t("edition",function(){return [_vm._l((_vm.internalHeaders),function(header){return _c('v-flex',{key:header.value},[(header.type === 'string' &&
                        _vm.showPropertyForEdition(header)
                        )?_c('v-text-field',{attrs:{"label":header.text},model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}}):_vm._e(),(header.type === 'number' &&
                        _vm.showPropertyForEdition(header)
                        )?_c('v-text-field',{attrs:{"type":"number","label":header.text},model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}}):_vm._e(),(header.type === 'boolean' &&
                        _vm.showPropertyForEdition(header)
                        )?_c('v-switch',{attrs:{"label":header.text},model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}}):_vm._e()],1)}),_vm._l((_vm.parentTables),function(p){return _c('v-select',{key:p.name,attrs:{"items":_vm.parentLists[p.name],"item-text":p.label,"item-value":p.primaryKey,"label":p.column},model:{value:(_vm.editedItem[p.column]),callback:function ($$v) {_vm.$set(_vm.editedItem, p.column, $$v)},expression:"editedItem[p.column]"}})}),_vm._l((_vm.parentSysCodes),function(p){return _c('v-select',{key:p.name,attrs:{"items":_vm.sysCodeLists[p.name],"item-text":p[_vm.description],"item-value":p.code,"label":p.column},model:{value:(_vm.editedItem[p.column]),callback:function ($$v) {_vm.$set(_vm.editedItem, p.column, $$v)},expression:"editedItem[p.column]"}})})]},{"editedItem":_vm.editedItem})],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItem}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.askDeleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }