<template>
  <div>
    <div class="ma-4" :key="tableName">
      <SysTableCRUD :headers="headers" :tableName="tableName" :title="title" :parentTables="parentTables"
        :primaryKey="primaryKey" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SysTableCRUD from "@/components/SysTableCRUD.vue";

export default Vue.extend({
  components: {
    SysTableCRUD,
  },
  data() {
    return {
      tableName: "sys_repair_method",
      title: "Repair method",
      primaryKey: "id",
      headers: [
        { name: "set_year", type: "number" },
        { name: "component", type: "parent" },
        { name: "element", type: "parent" },
        { name: "damage_type", type: "char" },
        { name: "damage_level", type: "string" },
        { name: "repair_method_eng", type: "text" },
        { name: "repair_method_lao", type: "text" },
        { name: "repair_level", type: "parent" },
        { name: "unit", type: "text" },
        { name: "unit_cost", type: "number" },
        { name: "deterioration_prediction", type: "boolean" },
        { name: "element_material", type: "parent" },
      ],
      parentTables: [
        { name: "sys-component", column: "component", primaryKey: "code", label: "description_eng" },
        { name: "sys-element", column: "element", primaryKey: "code", label: "description_eng" },
        { name: "sys-lcc-threshold", column: "repair_level", primaryKey: "repair_level", label: "description_eng" },
        { name: "sys-element-material", column: "element_material", primaryKey: "code", label: "description_eng" },
      ],
    };
  },
});
</script>

