<template>
  <div>
    <div class="ma-4" v-for="sysTable in sysTables" :key="sysTable.tableName">
      <SysTableCRUD
        :headers="sysTable.headers"
        :tableName="sysTable.tableName"
        :title="sysTable.title"
        :parentTables="sysTable.parentTables"
        :primaryKey="sysTable.primaryKey"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SysTableCRUD from "@/components/SysTableCRUD.vue";

export default Vue.extend({
  components: {
    SysTableCRUD,
  },
  data() {
    return {
      sysTables: [
        {
          tableName: "sys-lcc-scenario",
          title: "Lcc Scenario",
          primaryKey: "scenario_number",
          headers: [
            { name: "scenario_number", type: "number" },
            { name: "description_eng", type: "string" },
            { name: "description_lao", type: "string" },
          ],
        },
        {
          tableName: "sys-lcc-scenario-work",
          title: "Lcc Scenario work",
          primaryKey: "id",
          headers: [
            { name: "work_number", type: "number" },
            { name: "scenario_number", type: "parent" },
            { name: "sys_lcc_threshold_id", type: "parent" },
          ],
          parentTables: [
            {
              name: "sys-lcc-scenario",
              column: "scenario_number",
              primaryKey: "scenario_number",
              label: this.$i18n.locale === "la" ? "description_lao" : "description_eng",
            },
            {
              name: "sys-lcc-threshold",
              column: "sys_lcc_threshold_id",
              primaryKey: "id",
              label: this.$i18n.locale === "la" ? "description_lao" : "description_eng",
            },
          ],
        },
    ],
    };
  },
})
</script>