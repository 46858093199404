export default {
  "_-RDINF-road_id": "roadid",
  "_-RDINF-road_number": "roadnumber",
  "_-RDINF-road_link": "linkid",
  "_-BRDE1-direction": "direction",
  "_-BRDE1-bridge_name": "bridge_name",
  "_-BRDE1-crossing_object": "bridge_crossing",
  "_-BRDE1-specify_crossing_object": "bridge_crossing_specify",
  "_-BRDE1-river_name": "river_name",
  "_-BRDE1-provinces": "bridge_province",
  "_-BRDE1-districts": "bridge_district",
  "_-BRDE2-km": "chainage",
  "_-BRDE2-gps-altitude": "bridge_gps_z",
  "_-BRDE2-num_span": "nb_of_spans",
  "_-BRDE2-bridge_mat": "bridge_material",
  "_-BRDE2-other_bridgemat": "bridge_material_specify",
  "_-BRDE2-bridge_structure": "bridge_structure",
  "_-BRDE2-specify_bridge_type": "bridge_structure_specify",
  "_-BRDE2-bridge_length": "bridge_length",
  "_-BRDE2-span_length_max": "max_span_length",
  "_-BRDE2-span_length_min": "min_span_length",
  "_-BRDE2-year_construction": "year_of_construction",
  "_-BRDE2-repair": "bridge_element_repair",
  "_-BRDE2-last_repair_year": "latest_repair_year",
  "_-BRDE3-design_standard_bridge": "design_standard",
  "_-BRDE3-other_design": "design_standard_specify",
  "_-BRDE3-max_perss_road": "max_load",
  "_-BRDE3-funding": "source_of_funding",
  "_-BRDE3-carriage_width": "width_of_carriageway",
  "_-BRDE3-bridge_width": "bridge_width",
  "_-BRDE3-inboun_width": "width_of_inbound_lane",
  "_-BRDE3-outbound_width": "width_of_outbound_lane",
  "_-BRDE3-bridge_freeboard": "bridge_freeboard",
  "_-SUPST-structure_type": "superstructure_type",
  "_-SUPST-specify_structure_type": "superstructure_type_specify",
  "_-SUPST-material_type": "main_girder_material_type",
  "_-SUPST-main_girder_number": "nb_of_main_girders",
  "_-SUPST-deck_material": "deck_slab_material_type",
  "_-SUPST-other_material": "superstructure_other_members_material_type",
  "_-SUBST-pier_structure": "pier_structure",
  "_-SUBST-pier_material": "pier_material",
  "_-SUBST-height_substructure": "substructure_height",
  "_-SUBST-num_pier": "nb_of_pier",
  "_-SUBST-pier_shape": "pier_shape",
  "_-SUBST-num_column": "pier_number_of_columns",
  "_-SUBST-pier_diameter": "pier_diameter",
  "_-SUBST-pier_length": "pier_length",
  "_-SUBST-pier_breath": "pier_breadth",
  "_-SUBST-abutment_type": "abutment_type",
  "_-SUBST-foundation_type": "foundation_type",
  "_-SUBST-other_foundationtype": "foundation_type_specify",
  "_-SUBST-f_material_type": "foundation_material_type",
  "_-BEARI-body_type": "bearing_body_type",
  "_-BEARI-specify_body_type": "bearing_body_type_specify",
  "_-BEARI-seat_type": "bearing_seat_type",
  "_-BEARI-specify_seat_type": "bearing_seat_type_specify",
  "_-BEARI-fall_prevention_device_type": "bridge_fall_prevention_device_type",
  "_-EXIST-expansion_joint": "existence_of_expansion_joint",
  "_-EXIST-railing": "existence_of_railing_guardfence",
  "_-EXIST-noise_barrier": "existence_of_noise_barrier",
  "_-EXIST-traffice_light": "existence_of_lights_traffic_signs",
  "_-EXIST-wheelguard": "existence_of_wheel_guard",
  "_-EXIST-drainage_fac": "existence_of_drainage_facility",
  "_-EXIST-wing_wall": "existence_of_wing_wall",
  "_-EXIST-inspection_fac": "existence_of_inspection_facility",
  "_-EXIST-accessories": "existence_of_accessories",
  "_-EXIST-footpath": "existence_of_footpath",
  "_-EXIST-walkway": "type_of_footpath",
  "_-EXIST-detour": "existence_of_detour",
  "_-MATYP-mat_expansion_joint": "type_of_expansion_joint",
  "_-MATYP-specify_mat_expansion_joint": "type_of_expansion_joint_specify",
  "_-MATYP-mat_railing": "type_of_railing_guardfence",
  "_-MATYP-specify_mat_railing": "type_of_railing_guardfence_specify",
  "_-MATYP-mat_noise_barrier": "type_of_noise_barrier",
  "_-MATYP-specify_mat_noise_barrier": "type_of_noise_barrier_specify",
  "_-MATYP-mat_light": "type_of_lighting",
  "_-MATYP-specify_mat_light": "type_of_lighting_specify",
  "_-MATYP-mat_wheelguard": "type_of_wheelguard",
  "_-MATYP-specify_mat_wheelguard": "type_of_wheelguard_specify",
  "_-MATYP-mat_drainage": "type_of_drainage_facility",
  "_-MATYP-specify_mat_drainage": "type_of_drainage_facility_specify",
  "_-MATYP-mat_pavement": "type_of_pavement",
  "_-MATYP-specify_mat_pavement": "type_of_pavement_specify",
  "_-MATYP-mat_wingwall": "type_of_wing_wall",
  "_-MATYP-specify_mat_wingwall": "type_of_wing_wall_specify",
  "_-MATYP-mat_inspec_fac": "type_of_inspection_facility",
  "_-MATYP-specify_inspection_fac": "type_of_inspection_facility_specify",
  "_-MATYP-mat_accessories": "type_of_accessories",
  "_-MATYP-specify_mat_accessories": "type_of_accessories_specify",
  "_-UPDAT-update_by": "latest_update_user",
  // "_-UPDAT-update_date": "latest_update_date"
};

