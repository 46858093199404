<template class="ma-5">
  <div class="ma-4">
    <v-layout child-flex>
      <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')"
        :headers="internalHeaders"
        :items="tableItems"
        class="elevation-1"
        disable-pagination
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ tableTitle }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1000px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="green"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="create = true"
                  ><v-icon small class="pr-2">mdi-plus</v-icon> New
                  {{ tableTitle }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <slot name="edition" v-bind:editedItem="editedItem">
                      <v-flex
                        v-for="header in internalHeaders"
                        :key="header.value"
                      >
                        <v-text-field
                          v-if="
                            header.type === 'string' &&
                            showPropertyForEdition(header) &&
                            header.text != 'language'
                          "
                          v-model="editedItem[header.value]"
                          :label="header.text"
                        ></v-text-field>
                        <v-switch
                          v-if="
                            header.type === 'boolean' &&
                            showPropertyForEdition(header)
                          "
                          v-model="editedItem[header.value]"
                          :label="header.text"
                        ></v-switch>
                        <v-select
                          v-if="header.value === 'language'"
                          :items="['en', 'la']"
                          v-model="editedItem[header.value]"
                          :label="header.value + '*'"
                        ></v-select>
                      </v-flex>
                      <v-switch
                        v-if="!creation"
                        v-model="enablePassword"
                        label="Edit password"
                      ></v-switch>
                      <v-text-field
                        v-if="enablePassword"
                        v-model="editedItem['password']"
                        label="password*"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        @click:append="show = !show"
                      ></v-text-field>
                    </slot>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    {{ $t('Cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text v-if="create" @click="next">
                    Next
                  </v-btn>
                  <v-btn color="blue darken-1" text v-else @click="save">
                    {{ $t('Save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="1000px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >{{ $t('Cancel') }}</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItem">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogRights" max-width="1000px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <slot name="edition" v-bind:editedItem="editedItem">
                      <div class="d-flex my-6">
                        <h2 class="d-flex mr-4">Modules</h2>
                      </div>
                      <v-flex v-for="mod in modulesList" :key="mod.name">
                        <v-select
                          dense
                          v-if="
                            mod.name === 'module_settings' ||
                            mod.name === 'module_user_management'
                          "
                          v-model="editedItem[mod.name]"
                          :items="[
                            { name: 'None', value: 0 },
                            { name: 'Admin', value: 3 },
                          ]"
                          item-text="name"
                          item-value="value"
                          :label="$t(mod.name)"
                          :value="editedItem[mod.name] || 0"
                        ></v-select>
                        <v-select
                          v-else
                          dense
                          v-model="editedItem[mod.name]"
                          :items="[
                            { name: 'None', value: 0 },
                            { name: 'Browse', value: 1 },
                            { name: 'Edit', value: 2 },
                            { name: 'Admin', value: 3 },
                          ]"
                          item-text="name"
                          item-value="value"
                          :label="$t(mod.name)"
                          :value="editedItem[mod.name] || 0"
                        ></v-select>
                      </v-flex>
                      <div fluid>
                        <div class="d-flex mt-6">
                          <h2 class="d-flex mr-4">Provinces</h2>
                          <v-checkbox
                            class="pa-0 ma-0"
                            v-model="allTrue"
                            :label="$t('all-provinces')"
                          ></v-checkbox>
                        </div>
                        <div class="d-flex">
                          <div class="mr-6">
                            <div
                              v-for="province in provincesList.slice(0, 9)"
                              :key="province.value"
                            >
                              <v-checkbox
                                class="pa-0 ma-0"
                                dense
                                v-model="provinceValues[province.id]"
                                :label="province.name"
                              ></v-checkbox>
                            </div>
                          </div>
                          <div>
                            <div
                              v-for="province in provincesList.slice(9)"
                              :key="province.value"
                            >
                              <v-checkbox
                                class="pa-0 ma-0"
                                dense
                                v-model="provinceValues[province.id]"
                                :label="province.name"
                              ></v-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </slot>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    {{ $t('Cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> {{ $t('Save') }} </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon small @click="askDeleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:[`item.rights`]="{ item }">
          <v-icon color="blue" medium class="mr-2" @click="editRights(item)">
            mdi-note-text-outline
          </v-icon>
        </template>
      </v-data-table>
    </v-layout>
    <success-error-modal
      :dialog="dialogSuccess"
      :success="success"
      :successMessage="successMessage"
      :errorMessage="errorMessage"
      :errorData="errorData"
      @toggleModal="toggleModal"
    ></success-error-modal>
  </div>
</template>

<script>
import UserService from "@/services/UserService.js";
import SuccessErrorModal from "@/components/main/SuccessErrorModal.vue";

export default {
  components: {
    SuccessErrorModal,
  },
  data() {
    return {
      dialog: false,
      editedIndex: -1,
      editedItem: {
        active: "true",
        language: "en",
        module_bridge_management: 0,
        module_data_processing: 0,
        module_reporting: 0,
        module_analysis: 0,
        module_user_management: 0,
        module_settings: 0,
      },
      loading: false,
      errorData: null,
      dialogDelete: false,
      tableItems: [],
      enablePassword: true,
      creation: true,
      tableName: "user_account",
      title: "User account",
      primaryKey: "id",
      dialogRights: false,
      headers: [
        { name: "username", type: "string" },
        { name: "language", type: "string" },
        { name: "active", type: "boolean" },
        { name: "email", type: "string" },
        { name: "rights", type: "other" },
      ],
      success: false,
      successMessage: {
        en: "User modifications successfully saved !",
        la: "[LAO] User modifications successfully saved !",
      },
      errorMessage: {
        en: "An error has occured while trying to save user modifications",
        la: "[LAO] An error has occured while trying to save user modifications",
      },
      show: false,
      dialogSuccess: false,
      userProvinceData: null,
      create: false,
      allTrue: false,
    };
  },
  props: {
    addable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    editable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    deletable: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  computed: {
    description() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
    provincesList() {
      return this.$store.state.adm.provincesList.map((x) => ({
        id: x.id,
        name: x[this.description],
      }));
    },
    provinceValuesBase() {
      return this.$store.state.adm.provincesList.reduce(
        (acc, current) => ({ ...acc, [current.id]: false }),
        {}
      );
    },
    internalHeaders() {
      const headers = this.headers.map((header) => ({
        sortable: false,
        align: "center",
        value: header.name,
        text: header.name,
        type: header.type,
      }));
      headers.push({ value: "actions" });
      return headers;
    },
    formTitle() {
      return this.editedIndex === -1 ? this.addTitle : this.editTitle;
    },
    addTitle() {
      return `${this.$t("Add")} ${this.tableTitle}`;
    },
    editTitle() {
      return `${this.$t("Edit")} ${this.tableTitle}`;
    },
    tableTitle() {
      return this.$t(this.title);
    },
    modulesList() {
      return [
        { name: "module_bridge_management" },
        { name: "module_data_processing" },
        { name: "module_analysis" },
        { name: "module_reporting" },
        { name: "module_settings" },
        { name: "module_user_management" },
      ];
    },
    provinceValues() {
      return { ...this.provinceValuesBase, ...this.userProvinceData };
    },
  },
  watch: {
    allTrue(val) {
      Object.keys(this.provinceValues).forEach(
        (x) => (this.provinceValues[x] = val)
      );
    },
  },
  async mounted() {
    this.tableItems = (await UserService.index(this.tableName)).data;
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.tableItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.enablePassword = false;
      this.creation = false;
      this.dialog = true;
    },
    async editRights(item) {
      this.editedIndex = this.tableItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.userProvinceData = (
        await UserService.getProvince(this.editedItem.id)
      ).data.reduce((acc, current) => {
        if (!["id", "province_id", "user_id"].includes(current)) {
          acc[current.province_id] = true;
        }
        return acc;
      }, {});
      this.dialogRights = true;
    },
    askDeleteItem(item) {
      this.editedIndex = this.tableItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItem() {
      try {
        await UserService.delete(this.editedItem[this.primaryKey]);
        this.tableItems.splice(this.editedIndex, 1);
        this.close();
      } catch (err) {
        console.log(err);
      }
    },

    close() {
      this.enablePassword = true;
      this.creation = true;
      this.dialog = false;
      this.dialogDelete = false;
      this.dialogRights = false;
      this.loading = false;
      this.errorData = "";
      this.create = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    showPropertyForEdition(header) {
      if (header.value === "password" && !this.enablePassword) {
        return false;
      }
      return true;
    },
    async save() {
      try {
        let addedItem;
        this.loading = true;
        if (this.editedIndex > -1) {
          await UserService.update(this.editedItem);
          Object.assign(this.tableItems[this.editedIndex], this.editedItem);
        } else {
          let currentDate = new Date();
          this.editedItem.created_at = currentDate;
          this.editedItem.updated_at = currentDate;
          addedItem = (await UserService.register(this.editedItem)).data;
          this.tableItems.push(addedItem);
        }
        await UserService.upsert(
          this.provinceValues,
          addedItem ? addedItem.id : this.editedItem.id
        );
        this.close();
        this.success = true;
        this.errorData = "";
        this.dialogSuccess = true;
      } catch (err) {
        this.success = false;
        this.dialogSuccess = true;
        this.loading = false;
        this.errorData =
          err.response.data.details != null
            ? err.response.data.details.errors.map((e) => e.message)
            : "Error saving element.";
      }
    },
    toggleModal() {
      if (this.dialogSuccess) {
        this.dialogSuccess = false;
      }
    },
    next() {
      this.dialog = false;
      this.dialogRights = true;
      this.editedItem.module_bridge_management = 0;
      this.editedItem.module_data_processing = 0;
      this.editedItem.module_reporting = 0;
      this.editedItem.module_analysis = 0;
      this.editedItem.module_user_management = 0;
      this.editedItem.module_settings = 0;
    },
  },
};
</script>