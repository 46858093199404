import Api from "@/services/Api";

export default {
  index() {
    return Api().get("users");
  },
  indexAdmins() {
    return Api().get("admins");
  },
  get(id) {
    return Api().get(`user/${id}`);
  },
  getFacilitators() {
    return Api().get("facilitators");
  },
  register(credentials) {
    return Api().post("users", credentials);
  },
  update(credentials) {
    return Api().put("users", credentials);
  },
  delete(id) {
    return Api().delete(`user/${id}`);
  },
  upsert(provinces, user_id) {
    return Api().put(`user-province/${user_id}`, provinces);
  },
  getProvince(user_id) {
    return Api().get(`user-province/${user_id}`);
  },
};
