<template>
  <div>
    <div class="ma-2">
      <div class="text-center">
        <v-dialog v-model="dialog" width="800">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Data issues
            </v-card-title>

            <v-card-text>
              <div v-for="(problem, idx) in problems" :key="idx">
                {{ problem }}
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                {{ $t('Close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <div class="d-flex flex-column" v-if="false">
        <v-card class="pa-2">
          <v-toolbar flat>
            <v-toolbar-title>{{ $t("odk-uploads") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
          <UploadButton title="INVENTORY" @file-update="uploadFile" :data="{ type: 'inventory' }" small color="secondary"
            class="ma-2">
            <template slot="icon-left">
              <v-icon left dark>mdi-arrow-up-bold-box-outline</v-icon>
            </template>
          </UploadButton>

          <UploadButton :disabled="bridgesList.length === 0" title="INSPECTION" @file-update="uploadFile"
            :data="{ type: 'inspection' }" small color="secondary" class="ma-2">
            <template slot="icon-left">
              <v-icon left dark>mdi-arrow-up-bold-box-outline</v-icon>
            </template>
          </UploadButton>
        </v-card>
      </div>
    </div>
    <div class="ma-2">
      <div class="d-flex flex-column">
        <v-card class="pa-2">
          <v-toolbar flat>
            <v-toolbar-title>{{ $t("soundness-calculation") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-btn class="ml-4" color="green" @click="computeBaseline" :loading="computeLoading">
            {{ $t("Re-compute") }}
          </v-btn>
        </v-card>
      </div>
    </div>
  </div>
</template>


<script>
import UploadButton from "@/components/main/UploadButton.vue";
import * as XLSX from 'xlsx';
import inventoryColumns from "@/data/inventory-columns.js";
import BridgeService from "@/services/BridgeService.ts";
import ComputeService from "@/services/ComputeService";

const parseValueForDb = (value) => {
  if (value === "YES") {
    return true;
  }
  if (value === "NO") {
    return false;
  }
  if (value === " ") {
    return null;
  }
  return value;
};

export default {
  name: "DataProcessing",
  data() {
    return {
      bridgesList: [],
      computeLoading: false,
      problems: [],
      dialog: false,
      loading: false,
      error: false,
      error_message: "",
    };
  },
  components: {
    UploadButton,
  },
  async mounted() {
    this.bridgesList = (await BridgeService.index()).data;
  },
  methods: {
    async uploadFile(file) {
      this.message = "Uploading file";
      this.loading = true;
      this.result_dialog = true;
      if (file.size > this.maxFileSize) {
        this.error = true;
        this.error_message = `File size exceeded the maximum of ${this.maxFileSize}`;
        return;
      }
      let type = file.data.type;
      this.parseExcel(file, type);
    },
    async saveBridges(bridges) {
      await BridgeService.upsertBridges(bridges);
    },
    async saveInspections(inspections) {
      const bridgeSet = new Set();
      const duplicated = [];
      inspections.forEach((bridge, bridgeIdx) => {
        const key = bridge.inspection.id;
        if (bridgeSet.has(key)) {
          duplicated.push(`Duplicated inspection: ${key}`);
        }
        bridgeSet.add(key);
        bridge.span_repairs.forEach((sr, srIndex) => {
          if (sr.repairs.length > 0) {
            const repairSet = new Set();
            let idx = sr.repairs.length - 1;
            while (idx !== -1) {
              const key = `${sr.repairs[idx].component}-${sr.repairs[idx].element}-${sr.repairs[idx].repair_year}`;
              if (repairSet.has(key)) {
                duplicated.push(
                  `Duplicated repair: ${bridge.road_number}-${bridge.chainage}-${sr.span_number}-${key}`
                );
                console.log("splicing");
                inspections[bridgeIdx].span_repairs[srIndex].repairs.splice(
                  idx,
                  1
                );
              }
              repairSet.add(key);
              idx--;
            }
          }
        });
      });
      if (duplicated.length > 0) {
        this.problems.push(...duplicated);
        this.dialog = true;
        await BridgeService.saveInspections(inspections);
      } else {
        await BridgeService.saveInspections(inspections);
      }
    },

    ingestInventory(workbook) {
      this.problems = [];
      const XL_row_object = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets["Sheet1"]
      );

      const photosSheet = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets["_-PHOTO"]
      );

      const oldColumns = Object.keys(inventoryColumns);

      const bridges = XL_row_object.map((bridge) => {
        const newBridge = {};
        oldColumns.forEach((c) => {
          newBridge[inventoryColumns[c]] = parseValueForDb(bridge[c]);
        });

        newBridge["chainage"] = bridge["_-BRDE2-km"];
        const coordinates = bridge["_-BRDE2-gps"].split(",");

        newBridge["bridge_gps_x"] = coordinates[0];
        newBridge["bridge_gps_y"] = coordinates[1];
        newBridge.BridgePhotos = photosSheet
          .filter((photo) => photo["PARENT_KEY"] == bridge["KEY"])
          .map((p) => ({
            url: p["KEY"]
              .replaceAll("uuid:", "")
              .replaceAll("[", "")
              .replaceAll("]", "")
              .replaceAll("/", "_"),
            comment: p["_-PHOTO-BDPIC-caption"],
          }));

        return newBridge;
      });

      const bridgeSet = new Set();
      const duplicated = [];
      bridges.forEach((bridge) => {
        const key = `${bridge.roadnumber}-${bridge.chainage}`;
        bridge.key = key;
        if (bridgeSet.has(key)) {
          duplicated.push(key);
        }
        bridgeSet.add(key);
      });
      if (duplicated.length > 0) {
        this.problems.push(...duplicated);
        this.dialog = true;
      }
      this.saveBridges(bridges);
    },
    getBridgeId(roadnumber, chainage) {
      const bridgeCandidates = this.bridgesList.filter(
        (b) =>
          String(b.chainage) == String(chainage) &&
          String(b.roadnumber) == String(roadnumber)
      );
      if (bridgeCandidates.length === 1) {
        return bridgeCandidates[0].id;
      } else {
        this.problems.push(
          `No match in db for bridge: ${roadnumber}-${chainage}`
        );
        return null;
      }
    },
    getElementDamages(keySpan, inspection_span_id) {
      const elementDamages = [];
      this.bSpanBComp
        .filter((comp) => comp["PARENT_KEY"] == keySpan)
        .forEach((comp) => {
          this.bSpanBCompElmat
            .filter((elem) => elem["PARENT_KEY"] == comp["KEY"])
            .forEach((elem) => {
              this.bSpanBCompElmatDamty
                .filter((damg) => damg["PARENT_KEY"] == elem["KEY"])
                .forEach((damg) => {
                  const damage_type =
                    damg["_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-damage_type"];
                  const element_code =
                    damg["_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-bridge_element"];
                  const element_damage_id = `${inspection_span_id}x${element_code}x${damage_type}`;
                  elementDamages.push({
                    id: element_damage_id,
                    inspection_span_id,
                    component:
                      damg["_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-bridge_component"],
                    element:
                      damg["_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-bridge_element"],
                    element_material:
                      damg["_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-bridge_material"],
                    damage_type,
                    damage_grade_a:
                      damg["_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-grade_a"],
                    damage_grade_b:
                      damg["_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-grade_b"],
                    damage_grade_c:
                      damg["_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-grade_c"],
                    damage_grade_d:
                      damg["_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-grade_d"],
                    damage_grade_e:
                      damg["_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-grade_e"],
                    photos: this.bSpanBCompElmatDamtyPhoto
                      .filter((photo) => photo["PARENT_KEY"] == damg["KEY"])
                      .map((p) => ({
                        element_damage_id,
                        url: p["KEY"]
                          .replaceAll("uuid:", "")
                          .replaceAll("[", "")
                          .replaceAll("]", "")
                          .replaceAll("/", "_"),
                        comment:
                          p["_-BSPAN-BCOMP-ELMAT-DAMTY-PHOTO-photo_caption"],
                      })),
                  });
                });
            });
        });
      return elementDamages;
    },
    getRepairs(keySpan) {
      const spanRepairs = [];
      this.bSpanBComp
        .filter((comp) => comp["PARENT_KEY"] == keySpan)
        .forEach((comp) => {
          this.bSpanBCompElmat
            .filter((elem) => elem["PARENT_KEY"] == comp["KEY"])
            .forEach((elem) => {
              this.bSpanBCompElmatDamty
                .filter((damg) => damg["PARENT_KEY"] == elem["KEY"])
                .forEach((damg) => {
                  const repair_year = parseInt(
                    damg[
                    "_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-latest_element_repair"
                    ]
                  );
                  if (!isNaN(repair_year)) {
                    spanRepairs.push({
                      component:
                        damg[
                        "_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-bridge_component"
                        ],
                      element:
                        damg["_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-bridge_element"],
                      element_material:
                        damg["_-BSPAN-BCOMP-ELMAT-DAMTY-SUMMR-bridge_material"],
                      repair_year,
                    });
                  }
                });
            });
        });
      return spanRepairs;
    },
    ingestInspection(workbook) {
      this.problems = [];
      const inspection_year = 2021;
      const inspection_type = "A";

      this.sheet1 = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets["Sheet1"]
      );
      this.bSpan = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets["_-BSPAN"]
      );
      this.bSpanBComp = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets["_-BSPAN-BCOMP"]
      );
      this.bSpanBCompElmat = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets["_-BSPAN-BCOMP-ELMAT"]
      );
      this.bSpanBCompElmatDamty = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets["_-BSPAN-BCOMP-ELMAT-DAMTY"]
      );
      this.bSpanBCompElmatDamtyPhoto = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets["_-BSPAN-BCOMP-ELMAT-DAMTY-PHOTO"]
      );

      const payload = this.sheet1.map((bi) => {
        const bridge_id = this.getBridgeId(
          bi["_-RDINF-road_number"],
          bi["_-BGINF-km"]
        );

        return {
          bridge_id,
          road_number: bi["_-RDINF-road_number"],
          chainage: bi["_-BGINF-km"],
          inspection: {
            id: `${bridge_id}x${inspection_year}x${inspection_type}`,
            inspection_date: bi["_-BGINF-inspection_date"],
            inspector_name: bi["_-BGINF-inspector_name"],
            inspection_year,
            inspection_type,
            inspection_spans: this.bSpan
              .filter((span) => span["PARENT_KEY"] === bi["KEY"])
              .map((span) => {
                const span_number = span["_-BSPAN-SPINF-span_number"];
                const inspection_span_id = `${bridge_id}x${inspection_year}x${inspection_type}x${span_number}`;
                return {
                  id: inspection_span_id,
                  span_number,
                  span_length: Number(span["_-BSPAN-SPINF-span_length"]),
                  element_damages: this.getElementDamages(
                    span["KEY"],
                    inspection_span_id
                  ),
                };
              }),
          },
          span_repairs: this.bSpan
            .filter((span) => span["PARENT_KEY"] === bi["KEY"])
            .map((span) => {
              const span_number = span["_-BSPAN-SPINF-span_number"];
              return {
                span_number,
                repairs: this.getRepairs(span["KEY"]),
              };
            }),
        };
      });

      this.saveInspections(payload.filter((p) => p.bridge_id != null));
    },
    async computeBaseline() {
      this.computeLoading = true;
      await ComputeService.computeBaseline();
      this.computeLoading = false;
    },

    parseExcel(file, type) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: "binary",
        });
        if (type === "inventory") {
          this.ingestInventory(workbook);
        } else {
          this.ingestInspection(workbook);
        }
      };

      reader.onerror = function (ex) {
        console.log(ex);
      };

      reader.readAsBinaryString(file);
    },
  },
};
</script>
